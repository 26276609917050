/*! `Custom` Bootstrap 5 theme */
@import url(https://cdn.linearicons.com/free/1.0.0/icon-font.min.css);
@import url(https://fonts.googleapis.com/css?family=Montserrat:200,300,400,700,900);
$headings-font-family: Montserrat;

$enable-grid-classes: true;
$primary: #002c5f;
$secondary: #8e8e8e;
$success: #4175b1;
$danger: #cc330d;
$info: #5499ea;
$warning: #6e9fa5;
$light: #f8f8f8;
$dark: #1e2b37;
$enable-gradients: false;
$spacer: 1.2rem;
$border-width: 1px;
$btn-border-radius: 0;
$headings-font-weight: 900;
$border-radius: 0.25rem;
$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;
$btn-box-shadow: 0;
$badge-color: #5499ea;
$input-bg: #fbfbfb;
$small-font-size: 1em;

@import "node_modules/bootstrap/scss/bootstrap";

html {
  @include media-breakpoint-down("sm") {
    font-size: 14px;
  }
}
#listings {
  background-color: #f1f1f1;
}

#top {
  overflow: initial !important;
}
.modal-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  &.hidden {
    visibility: hidden;
    animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    .filter-modal {
      visibility: hidden;
      animation: scaleDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }
  }
}
.filter-modal {
  position: absolute;
  width: 100vw;
  background-color: white;
  top: 20%;
  bottom: 0;
  animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes scaleUp {
  0% {
    transform: scale(1) translateY(1000px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}

@keyframes scaleDown {
  0% {
    visibility: visible;
    transform: scale(1) translateY(0);
    opacity: 1;
  }
  100% {
    visibility: hidden;
    transform: scale(1) translateY(1000px);
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    visibility: visible;
    background: rgba(0, 0, 0, 0.6);
  }
  100% {
    visibility: hidden;
    background: rgba(0, 0, 0, 0);
  }
}
.footer {
  margin-top: 3rem;
}
.navbar {
  border-bottom: 1px solid #eee;
}

.small-image {
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  @include media-breakpoint-up("lg") {
    width: 5rem;
    height: 5rem;
  }
}

.carousel-item {
  padding-bottom: 60%;
}

.listings-card {
  max-width: 60rem;
  max-height: 20rem;
  text-decoration: none;
  cursor: pointer;
  @include media-breakpoint-down("sm") {
    height: 135px;
    h5 {
      font-size: 15px;
    }
    p {
      font-size: 11px;
    }
  }
  @include media-breakpoint-up("lg") {
    width: 60rem;
  }
}

.carousel-image {
  object-fit: contain;
  height: 100%;
}

.listings-image {
  object-fit: cover;
  object-position: center left;
  min-width: 150px;
  min-height: 15vw;
  max-height: 200px;
  width: 47.5%;
  flex: 0 1 30%;
}
.home-card {
  display: block;
  height: 100%;
  text-decoration: none;
  cursor: pointer;
  @include media-breakpoint-up("md") {
    /*max-width: 30rem;*/
  }
}
.card-image {
  object-fit: cover;
  max-height: 15rem;
}
.three-rows {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-outline-info:hover,
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
}

.cta-button-row .btn {
  width: 14rem;
  @include media-breakpoint-down("sm") {
    width: 11rem;
    font-size: 0.875em;
  }
}

.sticky-menu {
  a {
    text-decoration: none;
  }
  .btn-outline-primary {
    border-width: 0 !important;
  }
}

.summary-label {
  font-size: 0.875em;
}

.equipment-list {
  li {
    font-size: 1em;
    font-weight: 500;
  }
}

.box-cta-container {
  gap: 1rem;
  width: 100%;
  @include media-breakpoint-down("sm") {
    gap: 0.5rem;

    button {
      flex: 1 0 50%;
      font-size: 3.5vw;
    }
  }
}

.no-border {
  border-width: 0;
}

.cursor-pointer {
  cursor: pointer;
}
.extrasmall {
  font-size: 11px;
  margin-top: 2px;
}

.no-link {
  text-decoration: none;
}

.model-filter-item {
  cursor: pointer;
  img {
    opacity: 0.5;
  }
  &.active {
    img {
      opacity: 1;
    }
  }
}

.model-filter-item-desktop {
  cursor: pointer;
  img {
    opacity: 0.5;
  }
  &.active {
    img {
      opacity: 1;
    }
  }
}

.select-button {
  flex: 0 1 48.5%;
  padding: map-get($spacers, 2);
  margin-bottom: map-get($spacers, 2);
  background-color: $light;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 5px;
  &.active {
    border: 1px solid black;
  }
}

.justify-self-end {
  justify-self: end;
}
.bg-container {
  @include media-breakpoint-up("lg") {
    background-color: red;
    height: 37.5rem;
    margin-bottom: 12.5rem;
  }
}
.page-title {
  @include media-breakpoint-down("md") {
    font-size: $h3-font-size;
  }
}
.nav-tabs {
  .nav-item {
    flex: 0 1 30%;
    background: $gray-100;
  }
}
.container-small {
  position: relative;
  z-index: 999;
  max-width: 60rem;
  @include media-breakpoint-up("lg") {
    width: 60rem;
  }
}
.large-w-50 {
  @include media-breakpoint-up("lg") {
    width: 50%;
  }
}

.container-large {
  position: relative;
  z-index: 999;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: auto;
  margin-right: auto;
  max-width: 540px;
  @include media-breakpoint-up("md") {
    max-width: 720px;
  }
  @include media-breakpoint-up("lg") {
    max-width: 960px;
  }
  @include media-breakpoint-up("xl") {
    max-width: 1300px;
  }
}
.flex-parent {
  gap: 1rem;
}
.flex-50 {
  flex: 1 1 50%;
}

.product-title {
  @include media-breakpoint-up("lg") {
    flex: 0 1 70%;
  }
}

.large-rectangle {
  @include media-breakpoint-up("lg") {
    width: 100%;
    margin-top: -19rem;
    padding-top: 20rem;
    padding-bottom: 3rem;
    background-color: #f2f2f2;
  }
}
.form-box-container {
  pointer-events: none;
  z-index: 1200;
  padding-top: 22rem;
  position: absolute;
  height: 100%;
  .container-inner {
    top: 20px;
    position: sticky;
  }
  .form-box {
    pointer-events: all;
    flex: 0 0 40%;
    background-color: $primary;
    color: white;
  }
}
.overlay-container {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2000;
  background-color: white;
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2001;
  top: 40%;
  left: 0px;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.owl-item figure {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 60.37% 0 0 0; /* 34.37% = 100 / (w / h) = 100 / (640 / 220) */
  margin: 0;
}

.owl-item img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
}
